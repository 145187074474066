var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-row',{staticClass:"pt-5 pb-5"},[_c('v-col',{attrs:{"cols":"10"}},[_c('span',{staticClass:"titulo-header"},[_vm._v(" Detalle de cuenta de correo : "+_vm._s(_vm.cuentaData.cuenta)+" ")])])],1)],1),_c('div',[_c('v-row',{staticStyle:{"padding-top":"60px"}},[_c('v-col',{class:_vm.tabReplace == 1
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":"6"},on:{"click":function($event){return _vm.tabReplaceSelect(1)}}},[_c('span',{class:_vm.tabReplace == 1
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Detalles")])]),_c('v-col',{class:_vm.tabReplace == 2
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":"6"},on:{"click":function($event){return _vm.tabReplaceSelect(2)}}},[_c('span',{class:_vm.tabReplace == 2
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Correos")])])],1)],1),_c('div',{staticStyle:{"padding-top":"60px"}},[(_vm.tabReplace == 1)?_c('div',[_c('EmailDataIndex',{attrs:{"cuentaData":_vm.cuentaData}}),_c('EmailInfoIndex',{attrs:{"cuentaData":_vm.cuentaData}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }