<template>
    <div>
      <div>
        <v-row class="pt-5 pb-5">
          <v-col cols="10">
            <span class="titulo-header">
              Detalle de cuenta de correo : {{ cuentaData.cuenta }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-row style="padding-top: 60px;">
          <v-col
            cols="6"
            :class="
              tabReplace == 1
                ? 'text-center active-color'
                : 'text-center inactive-color'
            "
            @click="tabReplaceSelect(1)"
          >
            <span
              :class="
                tabReplace == 1
                  ? 'text-header-step-active'
                  : 'text-header-step-no-active'
              "
            >
              Detalles</span
            >
          </v-col>
          <v-col
            cols="6"
            :class="
              tabReplace == 2
                ? 'text-center active-color'
                : 'text-center inactive-color'
            "
            @click="tabReplaceSelect(2)"
          >
            <span
              :class="
                tabReplace == 2
                  ? 'text-header-step-active'
                  : 'text-header-step-no-active'
              "
            >
              Correos</span
            >
          </v-col>
        </v-row>
      </div>
      <div style="padding-top: 60px;">
        <div v-if="tabReplace == 1">
            <EmailDataIndex :cuentaData="cuentaData"/>
            <EmailInfoIndex :cuentaData="cuentaData"/>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  import EmailDataIndex from './EmailData/EmailDataIndex.vue'
  import EmailInfoIndex from './EmailInfoData/EmailInfoIndex.vue'
  import { getCuentaCorreoById } from "@/services/cuentasCorreo/cuentasCorreo.service.js";

  export default {
    components: {
        EmailDataIndex,
        EmailInfoIndex
    },
    data() {
      return {
        tabReplace: 1,
        cuentaData:{}
      };
    },
    methods: {
        async getCuentaCorreoById(){
            this.cuentaData = await getCuentaCorreoById(this.$route.params.id)
        }
    },
    mounted() {
        this.getCuentaCorreoById()
    },

  };
  </script>
  
  <style></style>
  