<template>
  <v-expansion-panel-content>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('changeTab', 2)"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3" lg="3" xl="3">
        <span class="text-header-data">
          Estatus
        </span>
        <br />
        <v-btn rounded dark style="background-color: #f1c21b;" :elevation="0">
          <span class="text-data-details" style="color:white;">
            {{ getStatusName(cuentaData.estatus) }}
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="3" xl="3">
        <span class="text-header-data">
          Tipo de Cuenta
        </span>
        <br />
          <span class="text-data-details">
            {{ getTipoAccountType(cuentaData.tipoCuenta) }}
          </span>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="3" xl="3">
        <span class="text-header-data">
          Usuario
        </span>
        <br />
          <span class="text-data-details">
            {{ cuentaData.cuenta ? cuentaData.cuenta : 'No disponible' }}
          </span>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="3" xl="3">
        <span class="text-header-data">
          Servidor IMAP
        </span>
        <br />
          <span class="text-data-details">
            {{ cuentaData.servidor ? cuentaData.servidor : 'No disponible' }}
          </span>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="3" xl="3">
        <span class="text-header-data">
          Tipo de seguridad
        </span>
        <br />
          <span class="text-data-details">
            {{ cuentaData.tipoSeguridad ? cuentaData.tipoSeguridad : 'No disponible' }}
          </span>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="3" xl="3">
        <span class="text-header-data">
          Tipo de autentificación
        </span>
        <br />
          <span class="text-data-details">
            {{ cuentaData.tipoAutentificacion ? cuentaData.tipoAutentificacion : 'No disponible' }}
          </span>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="3" xl="3">
        <span class="text-header-data">
          Puerto
        </span>
        <br />
          <span class="text-data-details">
            {{ cuentaData.puerto ? cuentaData.puerto : 'No disponible' }}
          </span>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="3" xl="3">
        <span class="text-header-data">
          Área de Negocio
        </span>
        <br />
          <span class="text-data-details">
            {{ cuentaData.areaNegocioId ? cuentaData.areaNegocioId : 'No disponible' }}
          </span>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="3" xl="3">
        <span class="text-header-data">
          Responsable de la cuenta
        </span>
        <br />
          <span class="text-data-details">
            {{ cuentaData.responsableCuentaId ? cuentaData.responsableCuentaId : 'No disponible' }}
          </span>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="3" xl="3">
        <span class="text-header-data">
          Regla de negocio a aplicar
        </span>
        <br />
          <span class="text-data-details">
            {{ cuentaData.reglaNegocioAplicable ? cuentaData.reglaNegocioAplicable : 'No disponible' }}
          </span>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="3" xl="3">
        <span class="text-header-data">
          Servicio de Notificaciones Salientes
        </span>
        <br />
          <span class="text-data-details">
            {{ cuentaData.servicioNotificacionesSalientes == 'true' ? 'Si ': 'No' }}
          </span>
      </v-col>
      
    </v-row>
  </v-expansion-panel-content>
</template>

<script>
import { getAccountTypes, getListadoEstatusEmail } from "@/services/cuentasCorreo/cuentasCorreo.service.js";

export default {
  components: {},
  props: {
    cuentaData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      polizaId: this.$route.params.id,
      tabReplace: 1,
      tiposCuenta: [],
      statusList: [],
    };
  },

  methods: {
    async getAccountTypes() {
      this.tiposCuenta = await getAccountTypes();
    },
    getTipoAccountType(id) {
      return this.tiposCuenta.find((e) => e.id == id).tipo;
    },
    async getListadoEstatusEmail() {
      this.statusList = await getListadoEstatusEmail();
    },
    getStatusName(id) {
      return this.statusList.find((e) => e.id == id).nombre;
    },
  },
  mounted() {
    this.getAccountTypes();
    this.getListadoEstatusEmail();
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-item-group .theme--light .v-expansion-panels {
  padding: 30px;
  border-radius: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
