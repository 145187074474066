<template>
  <v-expansion-panel-content>
    <v-row>
      <v-col cols="12" sm="4" md="6" lg="6" xl="6">
        <span class="text-header-data">
          Creado por
        </span>
        <br />
          <span class="text-data-details">
            {{ cuentaData.creadoPor ? (cuentaData.creadoPor) : 'No disponible' }}
          </span>
      </v-col> 
      <v-col cols="12" sm="4" md="6" lg="6" xl="6">
        <span class="text-header-data">
          Fecha creacion
        </span>
        <br />
          <span class="text-data-details">
            {{ cuentaData.fechaCreacion ? formatDate(cuentaData.fechaCreacion) : 'No disponible' }}
          </span>
      </v-col> 
      <v-col cols="12" sm="4" md="6" lg="6" xl="6">
        <span class="text-header-data">
          Modificado por
        </span>
        <br />
          <span class="text-data-details">
            {{ cuentaData.modificadoPor ? (cuentaData.modificadoPor) : 'No disponible' }}
          </span>
      </v-col> 
      <v-col cols="12" sm="4" md="6" lg="6" xl="6">
        <span class="text-header-data">
          Fecha edicion
        </span>
        <br />
          <span class="text-data-details">
            {{ cuentaData.ultimaModificacion ? formatDate(cuentaData.ultimaModificacion) : 'No disponible' }}
          </span>
      </v-col>      
    </v-row>
  </v-expansion-panel-content>
</template>

<script>
import { getAccountTypes } from "@/services/cuentasCorreo/cuentasCorreo.service.js";
import moment from "moment";
import { getUsersList } from "@/services/user/user.service";

export default {
  components: {},
  props: {
    cuentaData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      polizaId: this.$route.params.id,
      tabReplace: 1,
      tiposCuenta: [],
      usuarios:[]
    };
  },

  methods: {
    async getAccountTypes() {
      this.tiposCuenta = await getAccountTypes();
    },
    getTipoAccountType(id) {
      return this.tiposCuenta.find((e) => e.id == id).tipo;
    },
    formatDate(date){
      return moment(date).format('DD/MM/YYYY')
    },
    async getUsers(){
      this.usuarios = await getUsersList()
    },
    getUsersList(id){
      let usuario = this.usuarios.find((e) => e.id == id)
      return usuario ? usuario.nombreCompleto : null
    }
  },
  mounted() {
    this.getAccountTypes();
    this.getUsers()
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-item-group .theme--light .v-expansion-panels {
  padding: 30px;
  border-radius: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
